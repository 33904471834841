<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <pz-identity-document
                  ref="identity"
                  v-model="formulario1.identity"
                  prop="identity"
                  type="person"
                  :type-label="$t('forms_flujo.form1_persona.UY.tipo_doc')"
                  :number-label="$t('forms_flujo.form1_persona.UY.identity_dpi')"
                  type-hidden
          />
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="nombres"
                  :label="$t('forms_flujo.form1_persona.SP.nombre')"
                  :error="errors.get('nombres')"
          >
            <el-input
                    ref="nombres"
                    v-model.number="formulario1.nombres"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.SP.nombre')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >

          <el-form-item
                  prop="apellidos"
                  :label="$t('forms_flujo.form1_persona.SP.apellidos')"
                  :error="errors.get('apellido_paterno')"
          >
            <el-input
                    ref="apellidos"
                    v-model.number="formulario1.apellidos"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.SP.apellidos')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >

          <el-form-item
                  prop="fecha_nacimiento"
                  :label="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
                  :error="errors.get('fecha_nacimiento')"
          >
            <el-date-picker
                    ref="fecha_nacimiento"
                    v-model="formulario1.fecha_nacimiento"
                    type="date"
                    format="dd/MM/yyyy"
                    value-format="dd/MM/yyyy"
                    :picker-options="pickerOptions1"
                    :placeholder="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
        <el-form-item
                prop="genero"
                :label="$t('forms_flujo.form1_persona.SP.genero')"
                :error="errors.get('genero')"
        >
          <el-select
                  ref="genero"
                  v-model="formulario1.genero"
                  clearable
                  :placeholder="$t('forms_flujo.form1_persona.SP.genero')"
          >
            <el-option
                    v-for="item in genero"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    :placeholder="$t('forms_flujo.form1_persona.SP.genero')"
            />
          </el-select>
        </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="estado_civil_id"
            :label="$t('forms_flujo.form1_persona.UY.civil_state')"
            :error="errors.get('estado_civil_id')"
          >
            <el-select
              ref="estado_civil_id"
              v-model="formulario1.estado_civil_id"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.UY.civil_state')"
            >
              <el-option
                v-for="item in marital_status"
                :key="item.id"
                :label="item.estado"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.UY.civil_state')"
              />
            </el-select>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row :gutter="20">
          <el-col
              :xl="{span: 12}"
              :lg="{span: 12}"
              :md="{span: 12}"
              :sm="{span: 24}"
              :xs="{span: 24}"
          >
              <el-form-item
              prop="nro_indentificacion_tributaria"
              :label="$t('forms_flujo.form1_persona.SP.indentificacion_tributaria')"
              :error="errors.get('nro_indentificacion_tributaria')"
              >
                  <el-input
                  ref="nro_indentificacion_tributaria"
                  v-model="formulario1.info_extra.nro_indentificacion_tributaria"
                  :type="'text'"
                  :placeholder="$t('forms_flujo.form1_persona.SP.indentificacion_tributaria')"
                  />

              </el-form-item>
          </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="cuota_maxima"
                  :label="$t('forms_flujo.form1_persona.UY.cuota_maxima')"
                  :error="errors.get('cuota_maxima')"
          >
            <el-input
                ref="cuota_maxima"
                v-model="formulario1.cuota_maxima"
                :type="'text'"
                :placeholder="$t('forms_flujo.form1_persona.UY.cuota_maxima')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="telefono"
                  :label="$t('forms_flujo.form1_persona.UY.telefono')"
                  :error="errors.get('telefono')"
          >
            <el-input
                    ref="telefono"
                    v-model="formulario1.telefono"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.UY.telefono')"
            />
          </el-form-item>
        </el-col>
          <el-col
                  :xl="{span: 12}"
                  :lg="{span: 12}"
                  :md="{span: 12}"
                  :sm="{span: 24}"
                  :xs="{span: 24}"
          >
            <el-form-item
                    prop="email"
                    :label="$t('forms_flujo.form2_persona.UY.correo')"
                    :error="errors.get('email')"
            >
              <el-input
                      v-model="formulario1.email"
                      :type="'text'"
                      auto-complete="no"
                      :placeholder="$t('forms_flujo.form2_persona.UY.correo')"
              />
            </el-form-item>
          </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

import {required,EspacioBlanco,type} from "@/commons/utils/Rules";
import {getEstadoCivil} from "@/routes/api/resources";
import 'element-ui/lib/theme-chalk/display.css'
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";

export default {
    name: 'PzPersonForm1Impacta',
    components: {  PzFormFlujo, PzIdentityDocument},
    mixins: [PzBaseForm1],
    data() {

        return {
            genero: [
                {id: 'f', name: this.$t('forms_flujo.form1_persona.SP.femenino')},
                {id: 'm', name: this.$t('forms_flujo.form1_persona.SP.masculino')},
                {id: 'o', name: this.$t('forms_flujo.form1_persona.SP.otro')}
            ],
            marital_status: [],
            formulario1: {
                nombres: '',
                apellidos: '',
                estado_civil_id: '',
                fecha_nacimiento: '',
                genero: '',
                telefono: '',
                info_extra: {
                    tienes_cuentab: null,
                    nro_indentificacion_tributaria: '',
                },
                email: '',
                identity: {document: 1},
            },
            view_sucursal : false,
            rules_formulario1: {
                'identity.document': [required('')],
                'identity.number': [required(''),EspacioBlanco()],
                nombres: [required(''),EspacioBlanco()],
                apellidos: [required(''),EspacioBlanco()],
                estado_civil_id: [required('')],
                fecha_nacimiento: [required('')],
                info_extra: {
                    tienes_cuentab: [required('')],
                },
                genero: [required('')],
                cuota_maxima: [required('')],
                email: [
                    required(''),
                    type({field: '', type: 'email'})
                ],
                telefono: [required('')],
            },
            pickerOptions1: {
                disabledDate(time) {
                    return Date.now() < time.getTime();
                },
            },
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            let estado = getEstadoCivil().then((res) => {
                this.marital_status = res.data;
            }).catch(() => {
                this.marital_status = [];
            });
            return Promise.all([estado]).then(() => {
            }).finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name})
            });
        },
    },


}
</script>

