<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombres"
            :label="$t('forms_flujo.form1_persona.SP.nombre')"
            :error="errors.get('nombres')"
          >
            <el-input
              ref="nombres"
              v-model.number="formulario1.nombres"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.nombre')"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="apellidos"
            :label="$t('forms_flujo.form1_persona.SP.apellidos')"
            :error="errors.get('apellido_paterno')"
          >
            <el-input
              ref="apellidos"
              v-model.number="formulario1.apellidos"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.apellidos')"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <pz-identity-document
            v-model="formulario1.identity"
            prop="identity"
            type="person"
            :type-label="$t('forms_flujo.form1_persona.UY.tipo_doc')"
            :number-label="$t('forms_flujo.form1_persona.UY.identity_doc')"
            :number-hidden="condocumento"
          />
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono"
            :label="$t('forms_flujo.form1_persona.UY.telefono')"
            :error="errors.get('telefono')"
          >
            <el-input
              ref="telefono"
              v-model="formulario1.telefono"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.UY.telefono')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_nacimiento"
            :label="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
            :error="errors.get('fecha_nacimiento')"
          >
            <el-date-picker
              ref="fecha_nacimiento"
              v-model="formulario1.fecha_nacimiento"
              type="date"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :picker-options="pickerOptions1"
              :placeholder="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_ingreso"
            :label="$t('forms_flujo.form1_persona.UY.fecha_ingreso')"
            :error="errors.get('fecha_ingreso')"
          >
            <el-date-picker
              ref="fecha_ingreso"
              v-model="formulario1.fecha_ingreso"
              type="date"
              :picker-options="pickerOptions1"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :placeholder="$t('forms_flujo.form1_persona.UY.fecha_ingreso')"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="ingreso_mensual"
            :label="$t('forms_flujo.form1_persona.UY.ingreso_mensual')"
            :error="errors.get('ingreso_mensual')"
          >
            <el-input
              ref="ingreso_mensual"
              v-model.number="formulario1.ingreso_mensual"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.UY.ingreso_mensual')"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

import {required, EspacioBlanco} from "@/commons/utils/Rules";
import {getEstadoCivil, getSucursales} from "@/routes/api/resources";
import 'element-ui/lib/theme-chalk/display.css'
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import {getFromLocal} from "@/commons/utils/storage";

export default {
    name: 'PzPersonForm1Fenoreste',
    components: { PzFormFlujo, PzIdentityDocument},
    mixins: [PzBaseForm1],
    data() {
        return {
            reference: 'formulario1',
            marital_status: [],
            sucursales: [],
            formulario1: {
                nombres: '',
                apellidos: '',
                telefono: '',
                fecha_nacimiento: '',
                fecha_ingreso: '',
                ingreso_mensual: '',
                identity: {document: 1},
                comprobante_domicilio: null,
                comprobante_ingresos: null,
                clearing: null,
                sucursal_id:''
            },
            view_sucursal : false,
            rules_formulario1: {
                'identity.document': [required('')],
                'identity.number': [required(''),EspacioBlanco()],
                telefono: [required('')],
                sucursal_id: [required('')],
            },
            pickerOptions1: {
                disabledDate(time) {
                    return Date.now() < time.getTime();
                },
            },
        };
    },
    computed:{
        condocumento(){
            var docmun = localStorage.getItem('docnum');
            if(docmun){
                return    true;
            }

            return false
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        isReadOnly(data){
            let data_client = getFromLocal('data_client');
            if(data_client)
             return data_client[data]?true:false
            else
             return false;
        },
        fetchData() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            let estado = getEstadoCivil().then((res) => {
                this.marital_status = res.data;
            }).catch(() => {
                this.marital_status = [];
            });
            let getsucursales = '';
            if(this.$session.get('sucursal')) {
                this.view_sucursal = true;
                 getsucursales = getSucursales().then((res) => {
                    this.sucursales = res.data;
                }).catch(() => {
                    this.sucursales = [];
                });
            }
            return Promise.all([estado,getsucursales]).then(() => {
            }).finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name})
            });
        },
    },


}
</script>

